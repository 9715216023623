<template>
  <entity-form
    :id="role ? role.id : null"
    module-name="rolesList"
    :model="form"
    @submit="onSubmit"
  >
    <b-form-group
      label="Name"
    >
      <b-form-input
        v-model="form.name"
        placeholder="Name"
      />
    </b-form-group>

  </entity-form>
</template>

<script>
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import EntityForm from '@/components/ui/forms/EntityForm.vue'

export default {
  components: {
    EntityForm,
    BFormInput,
    BFormGroup,
  },
  props: {
    role: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        name: this.role ? this.role.name : null,
      },
    }
  },
  methods: {
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>

<style scoped>

</style>
