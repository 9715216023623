<template>
  <b-card>
    <b-card-header>
      <b-card-title>Users who are able to login in admin dashboard</b-card-title>
      <b-button
        variant="outline-primary"
        class="btn-icon float-right mb-auto mr-2"
        @click="onUserToAdminStart"
      >
        <feather-icon icon="PlusIcon" />
      </b-button>
    </b-card-header>
    <b-table
      :items="users"
      :fields="fields"
      striped
      responsive
      small
      :per-page="perPage"
      :current-page="currentPage"
      :busy="loading"
    >
      <template #cell(name)="data">
        <b-link :to="{ name: 'user-detail', params: { id: data.item.id } }">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </b-link>
      </template>

      <template #cell(email)="data">
        {{ data.item.email }}
      </template>

      <template #cell(roles)="data">
        <b-badge
          v-for="(role, index) in data.item.roles"
          :key="index"
          variant="light-primary"
        >
          {{ role }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <b-button
          variant="outline-danger"
          class="btn-icon"
          @click="onRemoveUser(data.item)"
        >
          <feather-icon icon="Trash2Icon" />
        </b-button>
      </template>
    </b-table>
    <pagination-row />

    <b-modal
      id="user-admin-modal"
      title="User able to login in admin dashboard"
      :no-close-on-backdrop="true"
      size="lg"
      @ok="onUserToAdminPost"
    >
      <label>Users</label>
      <custom-select
        v-model="newUser"
        module-name="usersList"
        label="email"
      />
      <label>Admin roles</label>
      <v-select
        v-model="roles"
        multiple
        :options="availableRoles"
      />
    </b-modal>
  </b-card>
</template>

<script>
import {
  BTable, BLink, BBadge, BCard, BCardHeader, BCardTitle, BButton,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import PaginationRow from '@/components/ui/tables/PaginationRow.vue'
import CustomSelect from '@/components/ui/forms/CustomSelect.vue'
import table from '@/mixins/table'
import SecurityAPI from '@/api/security'

export default {
  components: {
    PaginationRow,
    CustomSelect,
    vSelect,
    BTable,
    BLink,
    BBadge,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  mixins: [table],
  data() {
    return {
      loading: false,
      users: [],
      newUser: null,
      roles: [],
      availableRoles: ['ROLE_ADMIN_SUPPORT', 'ROLE_ADMIN_FULL_LIST', 'ROLE_ADMIN_EXTERNAL'],
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'roles',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
    }
  },
  watch: {
    users() {
      this.totalRows = this.users.length
    },
  },
  created() {
    this.totalRows = this.users.length
  },
  beforeMount() {
    this.getData()
  },
  methods: {
    getData() {
      SecurityAPI.fetchAdminUsers().then(response => {
        this.users = response
      })
    },
    onRemoveUser(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `User with email ${item.email} will not able to login to admin dashboard again and also will be logout`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          SecurityAPI.removeUserFromAdmin(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Remove user',
                icon: 'EyeOffIcon',
                text: 'Users rights was remove successfully.',
                variant: 'success',
              },
            })
            this.getData()
          })
        }
      })
    },
    onUserToAdminStart() {
      this.newUser = null
      this.$bvModal.show('user-admin-modal')
    },
    onUserToAdminPost() {
      if (this.users.find(user => user.id === this.newUser) || this.roles.length === 0) return

      SecurityAPI.addUserToAdmin(this.newUser, this.roles).then(response => {
        this.users.push(response)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Add user',
            icon: 'UserPlusIcon',
            text: 'Users rights was add successfully.',
            variant: 'success',
          },
        })
        this.roles = []
      })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'UserPlusIcon',
              text: error.data.detail,
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
