<template>
  <div>
    <b-card>
      <b-card-header>
        <b-card-title>Extended user roles</b-card-title>
        <b-button
          variant="outline-primary"
          class="btn-icon float-right mb-auto mr-2"
          @click="onCreateRole"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </b-card-header>
      <data-table
        :module-name="'rolesList'"
        :fields="fields"
        :sort-desc="false"
        deletable
        :actions="actions"
        @edit="onEditRole"
      >
        <template #cell(name)="data">
          <b-link :to="{ name: 'role-detail', params: { id: data.item.id } }">
            {{ data.item.name }}
          </b-link>
        </template>
      </data-table>
    </b-card>

    <admins-table />

    <b-modal
      id="role-modal"
      :title="title"
      :no-close-on-backdrop="true"
      size="lg"
      hide-footer
    >
      <role-form
        :role="roleEdit"
        @submit="onEditSubmit"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BLink, BCard, BCardHeader, BCardTitle, BButton,
} from 'bootstrap-vue'
import DataTable from '@/components/ui/tables/DataTable.vue'
import RoleForm from '@/components/forms/role/RoleForm.vue'
import AdminsTable from '@/views/security/role/components/AdmisTable.vue'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
]

export default {
  components: {
    DataTable,
    RoleForm,
    AdminsTable,
    BLink,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  data() {
    return {
      roleEdit: null,
      title: '',
      fields: [
        {
          key: 'name',
          sortable: false,
        },
      ],
      actions,
    }
  },
  methods: {
    onEditSubmit() {
      this.$bvModal.hide('role-modal')
    },
    onEditRole(item) {
      this.roleEdit = item
      this.title = 'Edit role'
      this.$bvModal.show('role-modal')
    },
    onCreateRole() {
      this.roleEdit = null
      this.title = 'Create role'
      this.$bvModal.show('role-modal')
    },
  },
}
</script>
